import { MIMETypes } from '@/enums';

export const preview = (file) => {
    const href = file.raw ? URL.createObjectURL(file.raw) : file.publicUrl;
    if (href) {
        window.open(href, '_blank');
    }
};

export const downloadXLSX = (data, fileName) => {
    const blob = new Blob([data], { type: MIMETypes.XLSX });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    link.click();
};
