<template>
    <ui-loading v-if="isLoadingPermissions || !$route.name" />
    <div
        v-else
        class="default-layout"
        :class="[{
            'is-nav-drawer-show': isNavDrawerShow && hasRouteAccess($route),
            'no-header': !isShowHeader,
            'no-transitions': stopTransitions,
        }, `default-layout--${mode}`]"
    >
        <header
            v-if="isShowHeader && hasAccess"
            class="default-layout__header"
        >
            <div class="default-layout__logo">
                <ui-logo />
                <router-link
                    v-if="business"
                    to="/"
                    tag="div"
                    class="default-layout__business-name"
                >
                    {{ business.name }}
                </router-link>
            </div>

            <nav-menu
                :items="menuItems"
                :params="menuParams"
            />

            <div class="default-layout__header-right">
                <business-balance v-if="business && $can(['ACCOUNT_READ']) && $can(['TRANSACTION_READ'])" />
                <el-badge
                    class="u-mr-lg"
                    :hidden="unreadNotificationCount === 0"
                    :value="unreadNotificationCount"
                >
                    <el-button
                        class="default-layout__notification-button"
                        type="text"
                        circle
                        @click="notificationDialogVisible = true"
                    >
                        <iconify-icon icon="fa6-regular:bell" />
                    </el-button>
                </el-badge>
                <user-panel v-if="!isBitrix24" />
            </div>
        </header>
        <main class="default-layout__main">
            <navigation-drawer v-if="hasNavDrawer && hasRouteAccess($route)">
                <context-menu />
            </navigation-drawer>

            <div class="default-layout__content">
                <div
                    v-if="!($route.meta && $route.meta.showPageHeader === false) && hasRouteAccess($route) && hasPageHeader"
                    class="default-layout__page-header"
                >
                    <portal-target
                        name="page-title"
                        class="page-title default-layout__page-title"
                    >
                        {{ routeTitle }}
                    </portal-target>

                    <portal-target
                        name="page-actions"
                        multiple
                        class="default-layout__page-actions"
                    />
                </div>

                <div class="default-layout__page-body">
                    <slot />
                </div>

                <portal-target
                    name="sticky-panel"
                    class="sticky-panel"
                />
            </div>
        </main>

        <site-notification-dialog :visible.sync="notificationDialogVisible" />
    </div>
</template>

<script>
import RoutePermissionMixin from '@/mixins/route-permission';
import NavMenu from '@/components/layout/NavMenu';
import ContextMenu from '@/components/layout/ContextMenu';
import NavigationDrawer from '@/components/layout/NavigationDrawer';
import UserPanel from '@/components/layout/UserPanel';
import BusinessBalance from '@/components/BusinessBalance';
import NavigationDrawerMixin from '@/mixins/navigation-drawer';
import { Wormhole } from 'portal-vue';
import { getRouteTitle } from '@/utils/route';
import SiteNotificationDialog from '@/components/profile/SiteNotificationDialog.vue';

export default {
    components: {
        SiteNotificationDialog,
        ContextMenu,
        NavigationDrawer,
        NavMenu,
        UserPanel,
        BusinessBalance,
    },

    mixins: [RoutePermissionMixin, NavigationDrawerMixin],

    props: {
        mode: {
            type: String,
            default: 'default',
        },
    },

    data () {
        return {
            notificationDialogVisible: false
        };
    },

    computed: {
        businessId () {
            return this.$store.getters['business/currentId'];
        },

        business () {
            return this.$store.getters['business/current'];
        },

        hasAccess () {
            return this.hasRouteAccess(this.$route);
        },

        isLoadingPermissions () {
            return this.$store.getters['security/isLoading'](this.businessId);
        },

        isBitrix24 () {
            return this.mode === 'bitrix24';
        },

        menuItems () {
            return this.filterRoutes(
                this.$router.options.routes
                    .find(route => route.name === (this.isBitrix24 ? 'Bitrix24' : 'BusinessDetail'))
                    ?.children ?? [],
            );
        },

        menuParams () {
            return this.isBitrix24 ? {} : { businessSlug: this.business?.slug };
        },

        routeTitle() {
            return getRouteTitle(this.$route);
        },

        hasPageHeader () {
            return this.routeTitle ||
                Wormhole.hasContentFor('page-title') ||
                Wormhole.hasContentFor('page-actions');
        },

        isShowHeader () {
            return Boolean(this.$route.meta.showHeader ?? true);
        },

        unreadNotificationCount () {
            return this.$store.getters['notification/totalUnreadCount'];
        }
    },
};
</script>
